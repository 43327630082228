// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { ChakraProvider, extendTheme, Heading, Button } from '@chakra-ui/react';
import { Link, useNavigate } from "react-router-dom";
import {Formik, Form} from 'formik';
// @ts-ignore
import styles from "../../assets/css/modular/apply-form.module.css";
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import * as Yup from 'yup';
import FormikControl from '../../components/form-components/FormikControl';
import ToastMessage from '../../components/utility/ToastMessage';
import axios from 'axios';

const theme = extendTheme({
    fonts : {
        heading : "Raleway",
        body : "Raleway",
    },
});

export default function ApplyFirstStep(){
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();
    let application_data = localStorage.getItem('application_data');
    let is_checked = true;

    if(!application_data){
        is_checked = false;
    }else{
       application_data = JSON.parse(application_data);
       if(!application_data.declaration || application_data.declaration !== true){
            is_checked = false;
       }
    }

    useEffect(() => {
        if(is_checked == false){
            ToastMessage("warning","Please check the declaration first");
            navigate('/apply-for-id');
        }
    }, []);

    const fetchData = () => {
        let init = {
            email: '',
            mobile: '',
            user_type: '',
            user_id: '',
            is_fetched: false,
        };

        init.user_type = application_data?.user_type;
        init.email = application_data?.email;
        init.mobile = application_data?.mobile;
        init.user_id = application_data?.user_id;
        init.is_fetched = application_data?.is_fetched;

        return init;
    }

    const validataionSchema = Yup.object({
        email: Yup.string().email("Invalid Email Address").required("Required"),
        mobile: Yup.string().matches(/^\d+$/, 'Please enter digits only').max(11, 'Mobile no must be at least 11 characters').required("Required"),
        user_type: Yup.string().required("Required"),
        user_id: Yup.string().matches(/^\d+$/, 'Please enter digits only').required("Required"),
    });

    const onSubmit = async (values) => {
        const { user_type, user_id, email, mobile } = values;

        //check whether user_id is changed for user type students
        if(application_data.user_id != user_id) {
            application_data.is_fetched = false;
        }

        application_data.user_type = user_type;
        application_data.user_id = user_id;
        application_data.email = email;
        application_data.mobile = mobile;
        application_data.first_step = true;

        //Check whether user is unique
        let params = {
            user_type,
            user_id,
            email,
            mobile
        };

        const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/api/user-unique-check`, {
            params: params
        });

        if(data.results == false) {
            ToastMessage('warning', 'This user already exists. Please login to access');
            setTimeout(
                function() {
                    navigate('/login');
                }, 5000);
        } else {
            if(user_type == 4) {
                // fetch Data from CSV if student type is hons masters student
                fetchCsvData(user_id);
            } else{
                resetIsFetched();
                localStorage.setItem('application_data',JSON.stringify(application_data));
                navigate('/apply-second-step');
            }
        }
    };

    const resetIsFetched = () => {
        application_data.fullname = '';
        application_data.sex = '';
        application_data.blood_group = '';
        application_data.batch = '';
        application_data.hall_id = '';
        application_data.dept_id = '';
        application_data.dob = '';
        application_data.hons_class_roll = '';
        application_data.is_fetched = false                                                                             ;
    }

    const fetchCsvData = async (user_id) => {
        let params = {
            registration_no: user_id
        };

        try {
            const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-csv-data-single`, {
                params: params
            });

            const {results} = data;

            if(results) {
                application_data.fullname = results?.fullname;
                application_data.sex = results?.sex;
                application_data.blood_group = results?.blood_group;
                application_data.batch = results?.batch;
                application_data.hall_id = results?.hall_id;
                application_data.dept_id = results?.department_id;
                application_data.dob = results?.dob;
                application_data.hons_class_roll = results?.hons_class_roll;
                application_data.is_fetched = true;
            }
        } catch (error) {
            ToastMessage('error', 'Something went wrong');
        }

        localStorage.setItem('application_data',JSON.stringify(application_data));
        navigate('/apply-second-step');
    }

    const getLabelForUserId = (param_user_type) => {
        if(['4','5'].includes(param_user_type)) {
            return "Registration No";
        } else if(['8'].includes(param_user_type)) {
            return "ID Number";
        } else {
            return "Employee Id";
        }
    }

    const getPlaceholderForUserId = (param_user_type) => {
        if(['4','5'].includes(param_user_type)) {
            return "Enter Registration No";
        } else if(['8'].includes(param_user_type)) {
            return "Enter ID Number";
        } else {
            return "Enter Employee Id";
        }
    }

    const key_options = [
        {key: 3, value: "Teacher"},
        {key: 4, value: "Honors Or Masters Student"},
        {key: 5, value: "Mphil Or Phd Student"},
        {key: 6, value: "Officer"},
        {key: 7, value: "Staff"},
        {key: 8, value: "Evening Course"},
    ];

    return(
        <ChakraProvider theme={theme}>
            <Header />
                <div className='contents'>
                    <div className='short-contents'>
                        <Heading className='header-text' size='lg'>
                            Apply for Id card : First Step
                        </Heading>
                        <br />

                        <div className={styles.apply_form}>
                            <Formik
                                initialValues={fetchData()}
                                validationSchema={validataionSchema}
                                onSubmit={onSubmit}
                            >
                                {
                                    formik => (
                                        <Form>
                                            <FormikControl required={true} control='radio' label='User Type' name='user_type' options={key_options} />
                                            {formik.values.user_type &&
                                                <>
                                                    <FormikControl required={true} control='input' type='text' label={getLabelForUserId(formik.values.user_type)} placeholder={getPlaceholderForUserId(formik.values.user_type)} name='user_id' />
                                                </>
                                            }
                                            <FormikControl required={true} control='input' type='email' label='Email' name='email' placeholder='aaaa@aaa.aaa' />
                                            <FormikControl required={true} control='input' type='text' label='Mobile No' name='mobile' placeholder='01000000000' />
                                            <div className={styles.neighbor_buttons}>
                                                <Link to='/apply-for-id'><Button colorScheme="blue" type="button">Prev</Button></Link>
                                                <Button colorScheme="green" type="submit">Next</Button>
                                            </div>
                                        </Form>
                                    )
                                }
                            </Formik>
                        </div>
                    </div>
                </div>
            <Footer />
        </ChakraProvider>
    )
}
