import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { getSex, getType } from "../../components/common/CommonFunctions";
import ToastMessage from '../utility/ToastMessage';
import { Button, Center, Flex, Image, Stack, Stat, StatLabel } from "@chakra-ui/react";
import SampleImage from '../../assets/images/No-image-found.jpg';
import UserInfoStyle from '../../assets/css/modular/user-info.module.css';
import { Link, useNavigate } from 'react-router-dom';
import LabelValue from '../adminFormGrid/LabelValue';
import CommonModal from '../common/CommonModal';

export default function UserInfo({userInfo, apply_id}) {
    const [thumb, setThumb] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [targetDeleteId, setTargetDeleteId] = useState(userInfo?.id);
    const [targetDeleteApplyId, setTargetDeleteApplyId] = useState(apply_id);
    const token = localStorage.getItem('my_token');

    const navigate = useNavigate();

    useEffect(() => {
        if(userInfo?.thumb){
            getThumb();
        }
    },[]);

    const getThumb = async () => {
        const config = {
            headers: { Authorization: `Bearer ${token}` },
            responseType: "blob",
            params: {
                filename: userInfo?.thumb
            }
        };

        try {
            var response = (await axios.get(`${process.env.REACT_APP_API_URL}/api/get-image-from-path`, config));
            if (response.status == 200) {
                const data = response.data;
                const blob = new Blob([data], { type: data.type });
                const url = URL.createObjectURL(blob);
                setThumb(url);
            }
        } catch ({response}) {
            if(response?.status == 404) {
                ToastMessage('warning', 'Image not found');
            }else{
                ToastMessage('error','Something went wrong');
            }
        }
    }

    const handleEdit = () => {

    }

    const handleDelete = () => {
        setShowDeleteModal(true);
    }
    
    const deleteData = (id) => {
        let config = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        };

        let deleteUrl = '';

        if(apply_id === undefined) {
            deleteUrl = `${process.env.REACT_APP_API_URL}/api/delete-user/${id}`;
        } else {
            deleteUrl = `${process.env.REACT_APP_API_URL}/api/delete-user-apply/${id}/${apply_id}`;
        }

        axios
            .delete(deleteUrl,{
                headers: config
            })
            .then((response) => {
                ToastMessage("success","Deleted Successfully");
                navigate(-1);
            })
            .catch((error) => {
                ToastMessage('error', 'Something went wrong');
            });
    }

    return (
        <div className={UserInfoStyle.details_div}>
            { showDeleteModal &&
                <CommonModal
                    reset={setShowDeleteModal}
                    setTargetDeleteId={setTargetDeleteId}
                    setTargetDeleteApplyId={setTargetDeleteApplyId}
                    deleteAction={deleteData}
                    targetDeleteId={userInfo?.id}
                    targetDeleteApplyId={targetDeleteApplyId}
                />
            }
            <div className={UserInfoStyle.info}>
                <LabelValue index='Fullname' value={userInfo?.fullname} />
                <LabelValue index='Email' value={userInfo?.email}/>
                <LabelValue index='Mobile' value={userInfo?.mobile}/>
                <LabelValue index='Blood Group' value={userInfo?.blood_group}/>
                <LabelValue index='Date of Birth(Y-m-d)' value={userInfo?.dob}/>
                <LabelValue index='Gender' value={getSex(userInfo?.sex)}/>
                <LabelValue index='Present Address' value={userInfo?.present_address}/>
                <LabelValue index='Permanent Address' value={userInfo?.permanent_address}/>
                <LabelValue index='Nid' value={userInfo?.nid}/>
                <LabelValue index='Birth Certificate' value={userInfo?.birth_certificate}/>
                <LabelValue index='Passport' value={userInfo?.passport}/>
            </div>

            <div className={UserInfoStyle.others}>
                <div className={UserInfoStyle.thumb}>
                    <Center>
                        <Flex direction={'column'}>
                            <Image
                                borderRadius='full'
                                objectFit='cover'
                                src={thumb ? thumb : SampleImage}
                                alt={userInfo?.fullname}
                            />
                            {/* <Center mt={'5px'}>
                                <Link to={'/user_dashboard'}>
                                    <Button colorScheme='teal' size='sm'>
                                        <span>Change Thumb</span>
                                    </Button>
                                </Link>
                            </Center> */}
                        </Flex>
                    </Center>
                </div>

                <div className={UserInfoStyle.log}>
                    <Center>
                        <Flex direction={'column'}>
                            <Stat>
                                <StatLabel>Created Date</StatLabel>
                                <p>{userInfo?.created_at}</p>
                            </Stat>

                            <Stat>
                                <StatLabel>Last Updated Date</StatLabel>
                                <p>{userInfo?.updated_at}</p>
                            </Stat>

                            <Stat mt={4}>
                                <Stack mt={2}>
                                    <Button colorScheme='red' size={'sm'} onClick={handleDelete}>Delete</Button>
                                </Stack>
                            </Stat>
                        </Flex>
                    </Center>
                </div>
            </div>
        </div>
    )
}
