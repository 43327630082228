import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../components/userEnd/common/UserLayout';
import axios from 'axios';
import ToastMessage from '../../components/utility/ToastMessage';
import CustomSpinner from '../../components/utility/CustomSpinner';
import UserInfo from '../../components/adminEnd/UserInfo';
import { Button, Center, Flex, Grid, GridItem, Heading, Stack } from '@chakra-ui/react';
import { getType, isEmployee } from '../../components/common/CommonFunctions';
import EmployeeInfo from '../../components/adminEnd/EmployeeInfo';
import StudentInfo from '../../components/adminEnd/StudentInfo';
import EmployeeIdCardWithValue from '../../components/id-card/EmployeeIdCardWithValue';
import HonsMastersStudentIdCardWithValue from '../../components/id-card/HonsMastersStudentIdCardWithValue';
import MphilPhdStudentIdCardWithValue from '../../components/id-card/MphilPhdStudentIdCardWithValue';
import EveningCourseStudentIdCardWithValue from '../../components/id-card/EveningCourseStudentIdCardWithValue';

export default function UserDashboard() {
    const token = localStorage.getItem('my_token');
    const userDetails = useContext(UserContext);

    const [userInfo, setUserInfo] = useState({});
    const [employeeInfo, setEmployeeInfo] = useState({});
    const [studentInfo, setStudentInfo] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchUser();
    }, [userDetails]);

    const fetchUser = async() => {
        setIsLoading(true);

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/user_detailed_info/${userDetails?.id}`,config)).data;
            if (apiResponse.error === false && apiResponse.results) {
                const { user_info, employee_info, student_info } = apiResponse?.results;
                setUserInfo(user_info);
                setEmployeeInfo(employee_info);
                setStudentInfo(student_info);
            }
        } catch (error) {
            ToastMessage('error','Something went wrong');
        }

        setIsLoading(false);
    }

    const handleEdit = () => {
        console.log('Tafif Bolod');
    }

    const IdCard = () => {
        const [thumb, setThumb] = useState('');

        useEffect(() => {
            if(userInfo?.thumb){
                getThumb();
            }
        },[]);
        
        const getThumb = async () => {
            const config = {
                headers: { Authorization: `Bearer ${token}` },
                responseType: "blob",
                params: {
                    filename: userInfo?.thumb
                }
            };
    
            try {
                var response = (await axios.get(`${process.env.REACT_APP_API_URL}/api/get-image-from-path`, config));
                if (response.status == 200) {
                    const data = response.data;
                    const blob = new Blob([data], { type: data.type });
                    const url = URL.createObjectURL(blob);
                    setThumb(url);
                }
            } catch ({response}) {
                if(response?.status == 404) {
                    ToastMessage('warning', 'Image not found');
                }else{
                    ToastMessage('error','Something went wrong');
                }
            }
        }

        return (
            <>
                { isEmployee(userInfo?.user_type) &&
                    <EmployeeIdCardWithValue
                        office={employeeInfo?.employee_details?.office}
                        thumb={thumb}
                        fullname={userInfo?.fullname}
                        employee_id={employeeInfo?.employee_details?.employee_id}
                        nid={userInfo?.nid}
                        blood_group={userInfo?.blood_group}
                        designation={employeeInfo?.employee_details?.designation}
                        dob={userInfo?.dob}
                        showHeader={true}
                    /> 
                }
                {userInfo?.user_type == '4' &&
                    <HonsMastersStudentIdCardWithValue
                        hall={studentInfo?.hons_masters_details?.hall}
                        thumb=""
                        fullname={userInfo?.fullname}
                        registration_no={studentInfo?.hons_masters_details?.registration_no}
                        department={studentInfo?.hons_masters_details?.dept_or_inst == '0' ? studentInfo?.hons_masters_details?.department : studentInfo?.hons_masters_details?.institute }
                        blood_group={userInfo?.blood_group}
                        batch={studentInfo?.hons_masters_details?.batch}
                        validity={'23'}
                        showHeader={true}
                    />
                }
                {userInfo?.user_type == '5' &&
                    <MphilPhdStudentIdCardWithValue
                        hall={studentInfo?.mphil_phd_details?.hall}
                        thumb=""
                        fullname={userInfo?.fullname}
                        registration_no={studentInfo?.mphil_phd_details?.registration_no}
                        department={studentInfo?.mphil_phd_details?.dept_or_inst == '0' ? studentInfo?.mphil_phd_details?.department : studentInfo?.mphil_phd_details?.institute }
                        blood_group={userInfo?.blood_group}
                        session={studentInfo?.mphil_phd_details?.session}
                        regularity={studentInfo?.mphil_phd_details?.type == '0' ? 'Regular' : 'Irregular'}
                        apply_for={studentInfo?.mphil_phd_details?.mphill_or_phd == '0' ? 'Mphil' : studentInfo?.mphil_phd_details?.mphill_or_phd == '1' ? 'Phd' : '' }
                        validity={'23'}
                        showHeader={true}
                    />
                }
                {userInfo?.user_type == '8' &&
                    <EveningCourseStudentIdCardWithValue
                        hall={studentInfo?.evening_course_student_details?.hall}
                        thumb=""
                        fullname={userInfo?.fullname}
                        registration_no={studentInfo?.evening_course_student_details?.registration_no}
                        blood_group={userInfo?.blood_group}
                        session={studentInfo?.evening_course_student_details?.session}
                        batch={studentInfo?.evening_course_student_details?.batch}
                        admission_year={studentInfo?.evening_course_student_details?.admission_year}
                        validity={'23'}
                        course_title={studentInfo?.evening_course_student_details?.course_title}
                        course_id={studentInfo?.evening_course_student_details?.course_id}
                        showHeader={true}
                    />
                }
            </>
        )
    }

    return (
        <>
            <Center>
                <Flex direction={'column'} justifyContent={'center'}>
                    <Heading as='h2' size='xl'>
                        Dashboard ({getType(userDetails?.user_type)})
                    </Heading>
                    {/* <Stack mt={2}>
                        <Button alignSelf={'center'} colorScheme='teal' size={'sm'} onClick={handleEdit}>Edit Personal Details</Button>
                    </Stack> */}
                </Flex>
            </Center>

            {isLoading
                ? <CustomSpinner />
                :
                    <>
                        <UserInfo userInfo={userInfo} />

                        <Center mt={2} mb={3}>
                            <Heading as="h2" size="xl">Academic Info</Heading>
                        </Center>
                        <Grid
                            templateColumns="repeat(4, 1fr)"
                            mb={4}
                        >
                            <GridItem justifySelf={'center'}>
                                <IdCard />
                            </GridItem>
                            <GridItem colSpan={3}>
                                {employeeInfo &&
                                    <EmployeeInfo employeeInfo={employeeInfo} /> 
                                }
                                {studentInfo &&
                                    <StudentInfo studentInfo={studentInfo} /> 
                                }
                            </GridItem>
                        </Grid>
                        
                    </>

            }
        </>
    )
}
